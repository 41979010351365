import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Box = makeShortcode("Box");
const Chocolate = makeShortcode("Chocolate");
const Flex = makeShortcode("Flex");
const Icon = makeShortcode("Icon");
const Button = makeShortcode("Button");
const InfoBlock = makeShortcode("InfoBlock");
const List = makeShortcode("List");
const Link = makeShortcode("Link");
const Aligner = makeShortcode("Aligner");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Box sx={{
      textAlign: "center"
    }} mdxType="Box">
      <h1>{`Funding Ideas or Events`}</h1>
      {
        /* <StatusBanner warning> Development Grants funding is currently closed </StatusBanner> */
      }
      <p><img parentName="p" {...{
          "src": "/images/illus_funding.png",
          "alt": "Funding"
        }}></img></p>
    </Box>
    <h2>{`Apply for Funding - Help Grow MakerDAO`}</h2>
    <p>{`We provide grants for projects and events that help strengthen Maker, the community, and Dai's adoption. We offer grants ranging from 5K - 50K Dai and distribute blocks of funds as teams reach predetermined milestones.`}</p>
    <Box sx={{
      backgroundColor: 'primaryMuted',
      borderRadius: '12px',
      padding: '12px',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap'
    }} mdxType="Box">
      <Chocolate mdxType="Chocolate">
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={"40px"} name="globe" mdxType="Icon" />
          <p>{`Apply to help build MakerDAO.`}</p>
          <Button to="/funding/development-grants/" sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} mdxType="Button">Development Grants</Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={"40px"} name="laptop" mdxType="Icon" />
          <p>{`Develop infrastructure and applications for the Maker ecosystem.`}</p>
          <Button to="/funding/hackathon-funding/" sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} mdxType="Button">Hackathon Funding</Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={"40px"} name="people" mdxType="Icon" />
          <p>{`Passionate about MakerDAO and DeFi? Planning a physical or virtual meetup? The microgrant program is for you!`}</p>
          <Button to="/funding/meetup-funding/" sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} mdxType="Button">Meetup Funding</Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={"40px"} name="lightbulb" mdxType="Icon" />
          <p>{`Apply for funding from the community to help build MakerDAO.`}</p>
          <Button to="/funding/community-projects/" sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} mdxType="Button">CDIPs</Button>
        </Flex>
      </Chocolate>
    </Box>
    <InfoBlock mdxType="InfoBlock">
      <Box sx={{
        paddingRight: '35px'
      }} mdxType="Box">
        <h2>{`We’ve Awarded Over 683,887 Dai to More Than 30 Projects`}</h2>
        <p>{`Started in February 2018, the MakerDAO Community Development Initiative funds teams creating infrastructure and applications within the Maker ecosystem.`}</p>
      </Box>
      <Box sx={{
        paddingLeft: '25px'
      }} mdxType="Box">
        <List mdxType="List">
          <Link to="https://dafnir.com/" mdxType="Link">Dafinir</Link>
          <Link to="https://sablier.finance/" mdxType="Link">Sablier</Link>
          <Link to="https://mkr.tools/" mdxType="Link">MKR.tools</Link>
          <Link to="https://www.pooltogether.us/" mdxType="Link">PoolTogether</Link>
        </List>
      </Box>
    </InfoBlock>
    <Aligner center mdxType="Aligner">
      <Button to="/funding/development-grants/" mdxType="Button">Apply for a Development Grant</Button>
    </Aligner>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      